import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import { TemplateCreator } from '../../containers/TemplateCreator';
import ApiClient from '../../util/ApiClient';
import { getParameterByName } from '../../util/queryString';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
@inject('rootStore')
@observer
export default class TemplateMain extends Component {
    constructor() {
        super();
        this.state = {
            questions: [],
            seriesName: '',
            seriesTTCEstimate: '',
            seriesMinSubscription: '',
            seriesId: '',
            supportedSubscriptions: [],
            showSavedMessage: true,
            showFinalSavedMessage: true
        };
    }

    componentDidMount() {
        const rootStore = this.props.rootStore;
        var seriesId = getParameterByName('seriesId', window.location.href);
        ApiClient.get('bot/service/series/' + seriesId).then((response) => {
            if (response.data) {
                this.setState({
                    questions: response.data.questions, seriesName: response.data.info.name,
                    seriesTTCEstimate: response.data.info.ttcEstimate, seriesMinSubscription: response.data.info.minSubscriptionPlan,
                    seriesId: response.data.info.seriesId, supportedSubscriptions: response.data.supportedSubscriptions,
                    seriesQuestionTypes: response.data.seriesQuestionTypes
                });
            }
        }).catch((error) => {
            alert(error.response.error)
        });
    }

    onSeriesNameChange = (e) => {
        this.setState({ seriesName: e.target.value })
    }
    onSeriesTTCEstimateChange = (e) => {
        this.setState({ seriesTTCEstimate: e.target.value })
    }
    onSeriesMinSubscriptionSelection = (value) => {
        this.setState({ seriesMinSubscription: value })
    }
    onQuestionDataChange = (which, prop) => (e) => {
        switch (prop) {
            case "question":
                var questionItems = [...this.state.questions];
                var questionItem = { ...questionItems[which] };
                questionItem.question.question = e.target.value;
                questionItems[which] = questionItem;
                this.setState({ questions: questionItems });
                break;
            case "rephrasedQuestion":
                var reQuestionItems = [...this.state.questions];
                var reQuestionItem = { ...reQuestionItems[which] };
                reQuestionItem.question.rephrasedQuestion = e.target.value;
                reQuestionItems[which] = reQuestionItem;
                this.setState({ questions: reQuestionItems });
                break;
            case "positiveText":
                var positiveTextItems = [...this.state.questions];
                var positiveTextItem = { ...positiveTextItems[which] };
                positiveTextItem.question.positiveText = e.target.value;
                positiveTextItems[which] = positiveTextItem;
                this.setState({ questions: positiveTextItems });
                break;
            case "positiveStepCounter":
                var itemsPositiveStepCounter = [...this.state.questions];
                var itemPositiveStepCounter = { ...itemsPositiveStepCounter[which] };
                itemPositiveStepCounter.question.positiveStepCounter = e.target.value;
                itemsPositiveStepCounter[which] = itemPositiveStepCounter;
                this.setState({ questions: itemsPositiveStepCounter });
                break;
            case "positiveHref":
                var itemsPositiveHref = [...this.state.questions];
                var itemPositiveHref = { ...itemsPositiveHref[which] };
                itemPositiveHref.question.positiveHref = e.target.value;
                itemsPositiveHref[which] = itemPositiveHref;
                this.setState({ questions: itemsPositiveHref });
                break;
            case "positiveQuestionType":
                let itemsPositiveQuestionType = [...this.state.questions];
                let itemPositiveQuestionType = { ...itemsPositiveQuestionType[which] };
                itemPositiveQuestionType.question.positiveQuestionType = e;
                itemsPositiveQuestionType[which] = itemPositiveQuestionType;
                this.setState({ questions: itemsPositiveQuestionType });
                break;
            case "toolTipMessage":
                let itemsToolTipMessage = [...this.state.questions];
                let itemToolTipMessage = { ...itemsToolTipMessage[which] };
                itemToolTipMessage.question.toolTipMessage = e.target.value;
                itemsToolTipMessage[which] = itemToolTipMessage;
                this.setState({ questions: itemsToolTipMessage });
                break;
            case "imageHelper":
                let itemsImageHelper = [...this.state.questions];
                let itemImageHelper = { ...itemsImageHelper[which] };
                itemImageHelper.question.imageHelper = e.target.value;
                itemsImageHelper[which] = itemImageHelper;
                this.setState({ questions: itemsImageHelper });
                break;
            case "autoLabel":
                let itemsAutoLabel = [...this.state.questions];
                let itemAutoLabel = { ...itemsAutoLabel[which] };
                itemAutoLabel.question.autoLabel = e.target.value;
                itemsAutoLabel[which] = itemAutoLabel;
                this.setState({ questions: itemsAutoLabel });
                break;
            case "negativeText":
                let itemsNegativeText = [...this.state.questions];
                let itemNegativeText = { ...itemsNegativeText[which] };
                itemNegativeText.question.negativeText = e.target.value;
                itemsNegativeText[which] = itemNegativeText;
                this.setState({ questions: itemsNegativeText });
                break;
            case "negativeStepCounter":
                let itemsNegativeStepCounter = [...this.state.questions];
                let itemNegativeStepCounter = { ...itemsNegativeStepCounter[which] };
                itemNegativeStepCounter.question.negativeStepCounter = e.target.value;
                itemsNegativeStepCounter[which] = itemNegativeStepCounter;
                this.setState({ questions: itemsNegativeStepCounter });
                break;
            case "negativeHref":
                var itemsNegativeHref = [...this.state.questions];
                var itemNegativeHref = { ...itemsNegativeHref[which] };
                itemNegativeHref.question.negativeHref = e.target.value;
                itemsNegativeHref[which] = itemNegativeHref;
                this.setState({ questions: itemsNegativeHref });
                break;
            case "neutralText":
                let itemsNeutralText = [...this.state.questions];
                let itemNeutralText = { ...itemsNeutralText[which] };
                itemNeutralText.question.neutralText = e.target.value;
                itemsNeutralText[which] = itemNeutralText;
                this.setState({ questions: itemsNeutralText });
                break;
            case "neutralStepCounter":
                let itemsNeutralStepCounter = [...this.state.questions];
                let itemNeutralStepCounter = { ...itemsNeutralStepCounter[which] };
                itemNeutralStepCounter.question.neutralStepCounter = e.target.value;
                itemsNeutralStepCounter[which] = itemNeutralStepCounter;
                this.setState({ questions: itemsNeutralStepCounter });
                break;
            case "neutralHref":
                var itemsNeutralHref = [...this.state.questions];
                var itemNeutralHref = { ...itemsNeutralHref[which] };
                itemNeutralHref.question.neutralHref = e.target.value;
                itemsNeutralHref[which] = itemNeutralHref;
                this.setState({ questions: itemsNeutralHref });
                break;
            case "showPositiveButton":
                let itemsShowPositiveButton = [...this.state.questions];
                let itemShowPositiveButton = { ...itemsShowPositiveButton[which] };
                itemShowPositiveButton.question.showPositiveButton = e.target.checked;
                itemsShowPositiveButton[which] = itemShowPositiveButton;
                this.setState({ questions: itemsShowPositiveButton });
                break;
            case "showNegativeButton":
                let itemsShowNegativeButton = [...this.state.questions];
                let itemShowNegativeButton = { ...itemsShowNegativeButton[which] };
                itemShowNegativeButton.question.showNegativeButton = e.target.checked;
                itemsShowNegativeButton[which] = itemShowNegativeButton;
                this.setState({ questions: itemsShowNegativeButton });
                break;
            case "showNeutralButton":
                let itemsShowNeutralButton = [...this.state.questions];
                let itemShowNeutralButton = { ...itemsShowNeutralButton[which] };
                itemShowNeutralButton.question.showNeutralButton = e.target.checked;
                itemsShowNeutralButton[which] = itemShowNeutralButton;
                this.setState({ questions: itemsShowNeutralButton });
                break;
            case "isBeforeSpreadSheetForm":
                let itemsIsBeforeSpreadSheetForm = [...this.state.questions];
                let itemIsBeforeSpreadSheetForm = { ...itemsIsBeforeSpreadSheetForm[which] };
                itemIsBeforeSpreadSheetForm.question.isBeforeSpreadSheetForm = e.target.checked;
                itemsIsBeforeSpreadSheetForm[which] = itemIsBeforeSpreadSheetForm;
                this.setState({ questions: itemsIsBeforeSpreadSheetForm });
                break;
            case "isSpreadSheetFormField":
                let itemsIsSpreadSheetFormField = [...this.state.questions];
                let itemIsSpreadSheetFormField = { ...itemsIsSpreadSheetFormField[which] };
                itemIsSpreadSheetFormField.question.isSpreadSheetFormField = e.target.checked;
                itemsIsSpreadSheetFormField[which] = itemIsSpreadSheetFormField;
                this.setState({ questions: itemsIsSpreadSheetFormField });
                break;
            case "isAfterSpreadSheetFormSubmitted":
                let itemsIsAfterSpreadSheetFormSubmitted = [...this.state.questions];
                let itemIsAfterSpreadSheetFormSubmitted = { ...itemsIsAfterSpreadSheetFormSubmitted[which] };
                itemIsAfterSpreadSheetFormSubmitted.question.isAfterSpreadSheetFormSubmitted = e.target.checked;
                itemsIsAfterSpreadSheetFormSubmitted[which] = itemIsAfterSpreadSheetFormSubmitted;
                this.setState({ questions: itemsIsAfterSpreadSheetFormSubmitted });
                break;
            case "isRepeaterSetup":
                let itemsIsRepeaterSetup = [...this.state.questions];
                let itemIsRepeaterSetup = { ...itemsIsRepeaterSetup[which] };
                itemIsRepeaterSetup.question.isRepeaterSetup = e.target.checked;
                itemsIsRepeaterSetup[which] = itemIsRepeaterSetup;
                this.setState({ questions: itemsIsRepeaterSetup });
                break;
            case "repeatMaxCounter":
                let itemsRepeatMaxCounter = [...this.state.questions];
                let itemRepeatMaxCounter = { ...itemsRepeatMaxCounter[which] };
                itemRepeatMaxCounter.question.repeatMaxCount = e.target.value;
                itemsRepeatMaxCounter[which] = itemRepeatMaxCounter;
                this.setState({ questions: itemsRepeatMaxCounter });
                break;
        }
    }
    addNewQuestion = () => {
        this.setState({
            questions: [...this.state.questions, {
                question: {
                    autoLabel: null,
                    imageHelper: null,
                    isAfterSpreadSheetFormSubmitted: null,
                    isBeforeSpreadSheetForm: null,
                    isRepeaterSetup: null,
                    isSpreadSheetFormField: null,
                    negativeStepCounter: null,
                    negativeHref: null,
                    negativeText: null,
                    neutralStepCounter: null,
                    neutralHref: null,
                    neutralText: null,
                    positiveQuestionType: null,
                    positiveStepCounter: 1,
                    positiveHref: null,
                    positiveText: "Next",
                    question: "{INSERT ALL QUESTION DETAILS AND CLICK SAVE}",
                    questionId: null,
                    questionOrder: 1,
                    repeatMaxCount: null,
                    rephrasedQuestion: null,
                    showNegativeButton: null,
                    showNeutralButton: null,
                    showPositiveButton: true,
                    toolTipMessage: null,
                    isBeforeSpreadSheetForm: false,
                    isSpreadSheetFormField: false,
                    isAfterSpreadSheetFormSubmitted: false
                }
            }]
        }, () => {
            window.scrollTo(0, document.body.scrollHeight);
        })
    }
    saveNewQuestionClick = (which) => () => {
        ApiClient.post('bot/service/add/builder/question', this.state.questions[which].question).then((response) => {
            if (response.data) {
                let items = [...this.state.questions];
                let item = { ...items[which] };
                item.question.questionId = response.data.id;
                items[which] = item;
                this.setState({ questions: items, showSavedMessage: false }, () => {
                    setTimeout(() => { this.setState({ showSavedMessage: true }) }, 1000);
                });
            }
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    updateQuestionClick = (which) => () => {
        ApiClient.post('bot/service/edit/builder/question', this.state.questions[which].question).then(() => {
            this.setState({ showSavedMessage: false }, () => {
                setTimeout(() => { this.setState({ showSavedMessage: true }) }, 1000);
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    onRemoveQuestionClick = (which) => () => {
        if (window.confirm("Are you sure you want to remove question " + (which + 1) + " from this series? NOTE: You must click Save Changes to fully remove.")) {
            var array = [...this.state.questions];
            array.splice(which, 1);
            this.setState({ questions: array });
        }
    }
    onSaveAllClick = () => {
        const ids = this.state.questions.map(function (obj, i) {
            return { questionId: obj.question.questionId, order: i };
        });
        ApiClient.post('bot/service/update/question/series', {
            seriesId: this.state.seriesId,
            ttcEstimate: this.state.seriesTTCEstimate,
            name: this.state.seriesName,
            minSubscriptionPlan: this.state.seriesMinSubscription,
            builderQuestionIds: ids
        }).then(() => {
            this.setState({ showFinalSavedMessage: false }, () => {
                setTimeout(() => { this.setState({ showFinalSavedMessage: true }) }, 2000);
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }

    onTaskDragEnd = (result) => {
        if (result.destination) {
            const qs = reorder(
                this.state.questions,
                result.source.index,
                result.destination.index
            );
            let items = [...this.state.questions];
            items = qs;
            this.setState({ questions: items });
        }
    }

    showIsLoading = (show) => {
        this.setState({ isLoading: show });
    }
    render() {
        const rootStore = this.props.rootStore;
        return (
            <Container fluid>
                {this.state.questions && this.state.questions.length > 0 &&
                    <TemplateCreator
                        questions={this.state.questions}
                        seriesName={this.state.seriesName}
                        onSeriesNameChange={this.onSeriesNameChange}
                        seriesTTCEstimate={this.state.seriesTTCEstimate}
                        onSeriesTTCEstimateChange={this.onSeriesTTCEstimateChange}
                        seriesMinSubscription={this.state.seriesMinSubscription}
                        onSeriesMinSubscriptionSelection={this.onSeriesMinSubscriptionSelection}
                        seriesId={this.state.seriesId}
                        supportedSubscriptions={this.state.supportedSubscriptions}
                        seriesQuestionTypes={this.state.seriesQuestionTypes}
                        addNewQuestion={this.addNewQuestion}
                        saveNewQuestionClick={this.saveNewQuestionClick}
                        updateQuestionClick={this.updateQuestionClick}
                        showSavedMessage={this.state.showSavedMessage}
                        onQuestionDataChange={this.onQuestionDataChange}
                        onRemoveQuestionClick={this.onRemoveQuestionClick}
                        onSaveAllClick={this.onSaveAllClick}
                        showFinalSavedMessage={this.state.showFinalSavedMessage}
                        onTaskDragEnd={this.onTaskDragEnd}
                    />
                }
            </Container>
        )
    }
}
