import { observable, action } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import AuthStore from './AuthStore';

export default class RootStore {

    @observable currentTheme;
    @observable error;
    @action clearError() {
        this.error = undefined;
    }

    constructor() {
        this.routerStore = new RouterStore();
        this.authStore = new AuthStore(this);
    }

}
