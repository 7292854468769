const config = {
    env: process.env.NODE_ENV || 'production',
    development: {
        api: 'http://localhost:5000/',
        base: 'http://localhost:5000/',
    },
    production: {
        api: 'https://api.prod-oregon.botit.live/',
        base: 'https://api.prod-oregon.botit.live/',
    }
};

export default config[config.env];