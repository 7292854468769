import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
import update from 'immutability-helper';
import Dropdown from 'react-bootstrap/Dropdown'
@inject('rootStore')
@observer
export default class BusinessEliteCreate extends Component {
    constructor() {
        super();
        this.state = {
            businessEliteId: '',
            searchUserId: '',
            searchedUserId: '',
            successRunSubjectDefault: '',
            successRunBodyDefault: '',
            successSenderEmailAddressDefault: '',
            noResultsSenderEmailAddressDefault: '',
            noResultsRecipientEmailAddressDefault: '',
            noResultsBodyDefault: '',
            noResultsSubjectDefault: '',
            confirmStepFailureEmailAddress: '',
            confirmStepFailureEmailSubject: '',
            confirmStepFailureEmailBody: '',
            confirmStepFailure: false,
            confirmStepFailureControls: '',
            moveMouseAfterFail: false,
            disableUserIdField: false,
            allowAirBots: false,
            allowRunNow: false,
            allowScheduleRun: false,
            token: '',
            customMessaging: false,
            billingType: 0,
            botRunsAllowed: 0,
            externalAPIs: [],
            externalAPITypeOptions: [],
            businessEliteAccountTypes: [],
            businessEliteInterpreterTypes: [],
            accountType: 0,
            interpreterType: 0,
            allowedSpreadSheetCount: null,
            allowedInfiniteScrollCount: null,
            allowedPaginationCount: null,
            companyName: null,
            phoneNumber: null,
            referredBy: null,
            collectionInterpreterItems: null
        };
    }
    componentDidMount() {
        this.getExternalAPITypeOptions();
    }
    getExternalAPITypeOptions = () => {
        ApiClient.get('bothtml/supported/external/api/type/options').then((response) => {
            this.setState({ externalAPITypeOptions: response.data }, () => {
                this.getInterpreterOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getInterpreterOptions = () => {
        ApiClient.get('bothtml/supported/interpreter/type/options').then((response) => {
            this.setState({ businessEliteInterpreterTypes: response.data }, () => {
                this.getBusinessEliteAccountTypes();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getBusinessEliteAccountTypes = () => {
        ApiClient.get('bothtml/supported/business/elite/account/type').then((response) => {
            this.setState({ businessEliteAccountTypes: response.data }, () => {
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    onBusinessProfileInputChange = (e) => {
        this.setState({ searchUserId: e.target.value })
    }
    onSearchBusinessProfileClick = () => {
        ApiClient.get('subscription/business/elite/profile/' + this.state.searchUserId).then((response) => {
            if (response && response.data) {
                this.setState({
                    businessEliteId: response.data.postId, searchedUserId: response.data.userId, disableUserIdField: true,
                    successRunSubjectDefault: response.data.successRunSubject,
                    successRunBodyDefault: response.data.successRunBody,
                    successSenderEmailAddressDefault: response.data.successSenderEmailAddress,
                    noResultsSenderEmailAddressDefault: response.data.noResultsSenderEmailAddress,
                    noResultsRecipientEmailAddressDefault: response.data.noResultsRecipientEmailAddress,
                    noResultsBodyDefault: response.data.noResultsBody,
                    noResultsSubjectDefault: response.data.noResultsSubject,
                    confirmStepFailureEmailAddress: response.data.confirmStepFailureEmailAddress,
                    confirmStepFailureEmailSubject: response.data.confirmStepFailureEmailSubject,
                    confirmStepFailureEmailBody: response.data.confirmStepFailureEmailBody,
                    confirmStepFailure: response.data.confirmStepFailure,
                    confirmStepFailureControls: response.data.confirmStepFailureControls,
                    moveMouseAfterFail: response.data.moveMouseAfterFail,
                    allowAirBots: response.data.allowAirBots,
                    token: response.data.token,
                    customMessaging: response.data.customMessaging,
                    billingType: response.data.billingType,
                    botRunsAllowed: response.data.botRunsAllowed,
                    allowRunNow: response.data.allowRunNow,
                    allowScheduleRun: response.data.allowScheduleRun,
                    externalAPIs: response.data.externalAPIs ? response.data.externalAPIs : [],
                    accountType: response.data.accountType,
                    allowedSpreadSheetCount: response.data.allowedSpreadSheetCount,
                    allowedInfiniteScrollCount: response.data.allowedInfiniteScrollCount,
                    allowedPaginationCount: response.data.allowedPaginationCount,
                    companyName: response.data.companyName,
                    phoneNumber: response.data.phoneNumber,
                    referredBy: response.data.referredBy,
                    collectionInterpreterItems: response.data.collectionInterpreterItems,
                    interpreterType:response.data.interpreterType
                }, () => { this.setState({ searchUserId: '' }) })
            }
        }).catch((error) => {
        });
    }
    addNewExternalAPI = () => {
        this.setState({
            externalAPIs: [...this.state.externalAPIs, {
                externalAPIType: null,
                apiKey: null,
                apiSecret: null,
                name: null,
                baseURL: null
            }]
        });
    }
    removeExternalAPI = (idx) => (e) => {
        if (window.confirm("Are you sure you want to remove API " + this.state.externalAPIs[idx].name)) {
            var array = this.state.externalAPIs;
            array.splice(idx, 1)
            this.setState({ externalAPIs: array });
        }
    }
    onApiNameChange = (index) => (e) => {
        this.setState
            ({
                externalAPIs:
                    update(this.state.externalAPIs, { [index]: { name: { $set: e.target.value } } })
            }
            );
    }
    onApiBaseUrlChange = (index) => (e) => {
        this.setState
            ({
                externalAPIs:
                    update(this.state.externalAPIs, { [index]: { baseURL: { $set: e.target.value } } })
            }
            );
    }
    onExternalAPIKeyChange = (index) => (e) => {
        this.setState
            ({
                externalAPIs:
                    update(this.state.externalAPIs, { [index]: { apiKey: { $set: e.target.value } } })
            }
            );
    }
    onExternalAPISecretChange = (index) => (e) => {
        this.setState
            ({
                externalAPIs:
                    update(this.state.externalAPIs, { [index]: { apiSecret: { $set: e.target.value } } })
            }
            );
    }
    onExternalAPITypeChange = (index) => (e) => {
        this.setState
            ({
                externalAPIs:
                    update(this.state.externalAPIs, { [index]: { externalAPIType: { $set: e.target.text } } })
            }
            );
    }
    onBusinessEliteAccountTypeChange = (e) => {
        this.setState
            ({
                accountType: e.target.text
            });
    }
    onBusinessEliteCollectionInterpreterChange = (e) => {
        this.setState
            ({
                interpreterType: e.target.text
            });
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row>
                    <Col xs={9}>
                        <Form.Group className="mb-3" controlId="userId">
                            <Form.Control type="text" placeholder={"Enter User ID or Email"} autoComplete={"off"} onChange={this.onBusinessProfileInputChange} value={this.state.searchUserId} />
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Button variant={"secondary"} onClick={this.onSearchBusinessProfileClick}>Search</Button>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Row>
                            <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <h3>Business Elite Profile</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        businessEliteId: this.state.businessEliteId,
                                        userId: this.state.searchedUserId,
                                        successRunSubject: this.state.successRunSubjectDefault,
                                        successRunBody: this.state.successRunBodyDefault,
                                        successSenderEmailAddress: this.state.successSenderEmailAddressDefault,
                                        noResultsSenderEmailAddress: this.state.noResultsSenderEmailAddressDefault,
                                        noResultsRecipientEmailAddress: this.state.noResultsRecipientEmailAddressDefault,
                                        noResultsSubject: this.state.noResultsSubjectDefault,
                                        noResultsBody: this.state.noResultsBodyDefault,
                                        confirmStepFailureEmailAddress: this.state.confirmStepFailureEmailAddress,
                                        confirmStepFailureEmailSubject: this.state.confirmStepFailureEmailSubject,
                                        confirmStepFailureEmailBody: this.state.confirmStepFailureEmailBody,
                                        confirmStepFailure: this.state.confirmStepFailure,
                                        confirmStepFailureControls: this.state.confirmStepFailureControls,
                                        moveMouseAfterFail: this.state.moveMouseAfterFail,
                                        allowAirBots: this.state.allowAirBots,
                                        token: this.state.token,
                                        customMessaging: this.state.customMessaging,
                                        billingType: this.state.billingType,
                                        botRunsAllowed: this.state.botRunsAllowed,
                                        allowRunNow: this.state.allowRunNow,
                                        allowScheduleRun: this.state.allowScheduleRun,
                                        externalAPIs: this.state.externalAPIs,
                                        allowedSpreadSheetCount: this.state.allowedSpreadSheetCount,
                                        allowedInfiniteScrollCount: this.state.allowedInfiniteScrollCount,
                                        allowedPaginationCount: this.state.allowedPaginationCount,
                                        accountType: this.state.accountType,
                                        companyName: this.state.companyName,
                                        phoneNumber: this.state.phoneNumber,
                                        referredBy: this.state.referredBy,
                                        collectionInterpreterItems: this.state.collectionInterpreterItems
                                    }}
                                    enableReinitialize={true}
                                    onSubmit={(values, actions) => {
                                        ApiClient.post(this.state.businessEliteId ? "subscription/update/business/elite" : "subscription/create/business/elite", {
                                            businessEliteId: values.businessEliteId,
                                            userId: values.userId,
                                            successRunSubject: values.successRunSubject,
                                            successRunBody: values.successRunBody,
                                            successSenderEmailAddress: values.successSenderEmailAddress,
                                            noResultsSenderEmailAddress: values.noResultsSenderEmailAddress,
                                            noResultsRecipientEmailAddress: values.noResultsRecipientEmailAddress,
                                            noResultsSubject: values.noResultsSubject,
                                            noResultsBody: values.noResultsBody,
                                            confirmStepFailureEmailAddress: values.confirmStepFailureEmailAddress,
                                            confirmStepFailureEmailSubject: values.confirmStepFailureEmailSubject,
                                            confirmStepFailureEmailBody: values.confirmStepFailureEmailBody,
                                            confirmStepFailure: values.confirmStepFailure,
                                            confirmStepFailureControls: values.confirmStepFailureControls,
                                            moveMouseAfterFail: values.moveMouseAfterFail,
                                            allowAirBots: values.allowAirBots,
                                            token: values.token,
                                            customMessaging: values.customMessaging,
                                            billingType: values.billingType,
                                            botRunsAllowed: values.botRunsAllowed,
                                            allowRunNow: values.allowRunNow,
                                            allowScheduleRun: values.allowScheduleRun,
                                            externalAPIs: this.state.externalAPIs,
                                            allowedSpreadSheetCount: values.allowedSpreadSheetCount ? values.allowedSpreadSheetCount : null,
                                            allowedInfiniteScrollCount: values.allowedInfiniteScrollCount ? values.allowedInfiniteScrollCount : null,
                                            allowedPaginationCount: values.allowedPaginationCount ? values.allowedPaginationCount : null,
                                            accountType: this.state.accountType,
                                            companyName: values.companyName,
                                            phoneNumber: values.phoneNumber,
                                            referredBy: values.referredBy,
                                            interpreterType: this.state.interpreterType,
                                            collectionInterpreterItems: values.collectionInterpreterItems
                                        }).then((response) => {
                                            if (response.status == 200) {
                                                actions.setStatus('Successfully Submitted');
                                                actions.setSubmitting(false);
                                                actions.setValues(values, false);
                                            }
                                            else {
                                                actions.setSubmitting(false);
                                                actions.setValues(values, false);
                                                this.setState({ apiError: "Error Please try again" })
                                            }

                                        }).catch((error) => {
                                            actions.setSubmitting(false);
                                            actions.setValues(values, false)
                                            this.setState({ apiError: error.response.data.error })

                                        })
                                    }}
                                >
                                    {formik => (
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Form.Group controlId="userId">
                                                <Form.Label>User ID</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userId} disabled={this.state.disableUserIdField} placeholder="User ID" required />
                                            </Form.Group>
                                            <Form.Group controlId="companyName">
                                                <Form.Label>Business Name</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.companyName} placeholder="Business Name" />
                                            </Form.Group>
                                            <Form.Group controlId="phoneNumber">
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phoneNumber} placeholder="Phone Number" />
                                            </Form.Group>
                                            <Form.Group controlId="referredBy">
                                                <Form.Label>Referenced By</Form.Label>
                                                <Form.Control as="textarea" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.referredBy} placeholder="How Did They Hear About Bot-It?" />
                                            </Form.Group>
                                            <Form.Group controlId="accountType">
                                                <Form.Label>Account Type</Form.Label>
                                                <Dropdown style={{ marginBottom: '16px' }}>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                                    {this.state.accountType != null && this.state.businessEliteId?
                                                                        typeof this.state.accountType === 'number' && this.state.businessEliteAccountTypes && this.state.businessEliteId ?
                                                                            this.state.businessEliteAccountTypes[this.state.accountType] :
                                                                            this.state.accountType
                                                                        :   "--Select Business Elite Account Type--"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.businessEliteAccountTypes.map((typ) => (
                                                            <Dropdown.Item onClick={this.onBusinessEliteAccountTypeChange}>{typ}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Form.Group>
                                            <Form.Group controlId="successRunSubject">
                                                <Form.Label>Email Results Subject</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.successRunSubject} placeholder="Email Results Subject" />
                                            </Form.Group>
                                            <Form.Group controlId="successRunBody">
                                                <Form.Label>Email Results Content</Form.Label>
                                                <Form.Control onChange={formik.handleChange} as="textarea" onBlur={formik.handleBlur} value={formik.values.successRunBody} placeholder="Email Results Content" rows="3" minLength={6} />
                                            </Form.Group>
                                            <Form.Group controlId="successSenderEmailAddress">
                                                <Form.Label>Results Sender Email Address</Form.Label>
                                                <Form.Control type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.successSenderEmailAddress} placeholder="Results Sender Email Address" />
                                            </Form.Group>
                                            <Form.Group controlId="noResultsSenderEmailAddress">
                                                <Form.Label>No Results Sender Email Address</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noResultsSenderEmailAddress} placeholder="No Results Sender Email" />
                                            </Form.Group>
                                            <Form.Group controlId="noResultsRecipientEmailAddress">
                                                <Form.Label>No Result Recipient Email Address</Form.Label>
                                                <Form.Control type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noResultsRecipientEmailAddress} placeholder="No Results Recipient Email" />
                                            </Form.Group>
                                            <Form.Group controlId="noResultsSubject">
                                                <Form.Label>No Result Subject</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noResultsSubject} placeholder="No Results Subject" />
                                            </Form.Group>
                                            <Form.Group controlId="noResultsBody">
                                                <Form.Label>No Results Content</Form.Label>
                                                <Form.Control onChange={formik.handleChange} as="textarea" onBlur={formik.handleBlur} value={formik.values.noResultsBody} placeholder="No Results Content" rows="3" minLength={6} />
                                            </Form.Group>
                                            <Form.Group controlId="interpreterTypeType">
                                                <Form.Label>Collection Exchange Type</Form.Label>
                                                <Dropdown style={{ marginBottom: '16px' }}>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                                    {this.state.interpreterType != null ?
                                                                        typeof this.state.interpreterType === 'number' ?
                                                                            this.state.businessEliteInterpreterTypes[this.state.interpreterType] :
                                                                            this.state.interpreterType
                                                                        :   "--Select Exchange Type--"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.state.businessEliteInterpreterTypes.map((typ) => (
                                                            <Dropdown.Item onClick={this.onBusinessEliteCollectionInterpreterChange}>{typ}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Form.Group>
                                            <Form.Group controlId="collectionInterpreterItems">
                                                <Form.Label>Dictionary Items</Form.Label>
                                                <Form.Control as="textarea" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.collectionInterpreterItems} placeholder="List of Items to Lookup" />
                                            </Form.Group>
                                            <Form.Group controlId="confirmStepFailure">
                                                <Form.Check type="checkbox" label="Enable Confirm Step Failure" onBlur={formik.handleBlur} checked={formik.values.confirmStepFailure} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="moveMouseAfterFail">
                                                <Form.Check type="checkbox" label="Move Mouse After Failure" onBlur={formik.handleBlur} checked={formik.values.moveMouseAfterFail} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="allowAirBots">
                                                <Form.Check type="checkbox" label="Allow Air Bots" onBlur={formik.handleBlur} checked={formik.values.allowAirBots} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="allowRunNow">
                                                <Form.Check type="checkbox" label="Allow Run Now" onBlur={formik.handleBlur} checked={formik.values.allowRunNow} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="allowScheduleRun">
                                                <Form.Check type="checkbox" label="Allow Schedule Run" onBlur={formik.handleBlur} checked={formik.values.allowScheduleRun} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="customMessaging">
                                                <Form.Check type="checkbox" label="Custom Messaging" onBlur={formik.handleBlur} checked={formik.values.customMessaging} onChange={formik.handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="confirmStepFailureEmailAddress">
                                                <Form.Label>Confirm Step Failure Email Address</Form.Label>
                                                <Form.Control type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmStepFailureEmailAddress} placeholder="Step Failure Email Address" />
                                            </Form.Group>
                                            <Form.Group controlId="confirmStepFailureEmailSubject">
                                                <Form.Label>Confirm Step Failure Email Subject</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmStepFailureEmailSubject} placeholder="Step Failure Email Subject" />
                                            </Form.Group>
                                            <Form.Group controlId="confirmStepFailureEmailBody">
                                                <Form.Label>Confirm Step Failure Email Body</Form.Label>
                                                <Form.Control as="textarea" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmStepFailureEmailBody} placeholder="Step Failure Email Body" minLength={6} />
                                            </Form.Group>
                                            <Form.Group controlId="confirmStepFailureControls">
                                                <Form.Label>Confirm Step Failure Control ID's</Form.Label>
                                                <Form.Control type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmStepFailureControls} placeholder="Step Failure Control ID's" />
                                            </Form.Group>
                                            <Form.Group controlId="token">
                                                <Form.Label>Token</Form.Label>
                                                <Form.Control onChange={formik.handleChange} as="textarea" onBlur={formik.handleBlur} value={formik.values.token} placeholder="API Token" rows="3" minLength={6} />
                                            </Form.Group>
                                            <Form.Group controlId="botRunsAllowed">
                                                <Form.Label>Number of Run Bots Allowed</Form.Label>
                                                <Form.Control type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.botRunsAllowed} placeholder="Run Bot Allowed" />
                                            </Form.Group>
                                            <Form.Group controlId="allowedSpreadSheetCount">
                                                <Form.Label>Number of Spreadsheet Row Count Allowed</Form.Label>
                                                <Form.Control type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.allowedSpreadSheetCount} placeholder="Spreadsheet Rows Allowed. Leave empty for unlimited." />
                                            </Form.Group>
                                            <Form.Group controlId="allowedInfiniteScrollCount">
                                                <Form.Label>Number of Infinite Scroll Pages Count Allowed</Form.Label>
                                                <Form.Control type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.allowedInfiniteScrollCount} placeholder="Infinite Scroll Pages Allowed. Leave empty for unlimited." />
                                            </Form.Group>
                                            <Form.Group controlId="allowedPaginationCount">
                                                <Form.Label>Number of Pagination Count Allowed</Form.Label>
                                                <Form.Control type="number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.allowedPaginationCount} placeholder="Pagination Count Allowed. Leave empty for unlimited. " />
                                            </Form.Group>
                                            <Form.Group controlId="billingType">
                                                <Form.Label>Billing Type</Form.Label>
                                                <Form.Select aria-label="Select Billing Type" onChange={formik.handleChange} value={formik.values.billingType}>
                                                    <option>Select a Billing Type</option>
                                                    <option value={0}>By Runs</option>
                                                    <option value={1}>By Bot Count</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <h3 hidden={!this.state.businessEliteId}>API Keys</h3><Button variant='success' hidden={!this.state.businessEliteId} onClick={this.addNewExternalAPI}>Add New API</Button>
                                            {this.state.externalAPIs && this.state.externalAPIs.length > 0 &&
                                                this.state.externalAPIs.map((api, idx) => (
                                                    <div>
                                                        <Form.Group controlId="externalAPIType1">
                                                            <Form.Label>External API Type</Form.Label>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="externalAPIType">
                                                                    {this.state.externalAPIs[idx].externalAPIType != null ?
                                                                        typeof this.state.externalAPIs[idx].externalAPIType === 'number' ?
                                                                            this.state.externalAPITypeOptions[this.state.externalAPIs[idx].externalAPIType] :
                                                                            this.state.externalAPIs[idx].externalAPIType
                                                                        : "--Select External API Type--"}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {this.state.externalAPITypeOptions.map((typ) => (
                                                                        <Dropdown.Item onClick={this.onExternalAPITypeChange(idx)}>{typ}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="apiName">
                                                            <Form.Label>API Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Custom Name" onChange={this.onApiNameChange(idx)} value={api.name} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="baseURL">
                                                            <Form.Label>Base URL</Form.Label>
                                                            <Form.Control type="text" placeholder="Example https://automation.botit.com/api/v1/" onChange={this.onApiBaseUrlChange(idx)} value={api.baseURL} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="apiKey">
                                                            <Form.Label>API Key</Form.Label>
                                                            <Form.Control type="text" placeholder="Custom API Key" onChange={this.onExternalAPIKeyChange(idx)} value={api.apiKey} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="apiSecret">
                                                            <Form.Label>API Secret</Form.Label>
                                                            <Form.Control type="text" placeholder="Custom API Secret" onChange={this.onExternalAPISecretChange(idx)} value={api.apiSecret} />
                                                        </Form.Group>
                                                        <Button variant='danger' style={{ float: 'right', marginBottom: '12px' }} onClick={this.removeExternalAPI(idx)}>Delete</Button>
                                                    </div>
                                                ))}

                                            <Row style={{ textAlign: 'center', marginTop: '30px' }}>
                                                <Col lg={12}>
                                                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Save"}</Button>
                                                </Col>
                                            </Row>
                                            <Row style={{ textAlign: 'center' }}>
                                                <Col lg={12}>
                                                    <span style={{ color: 'green' }}>{formik.status}</span>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        )
    }
}