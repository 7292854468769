import React from 'react';
import { inject, observer } from "mobx-react";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import bwLogo from '../assets/Logo_BnW@3x.png';
import '../css/navbar.css';
import { Container } from 'react-bootstrap';
export const AppBar = inject("rootStore")(observer((props) => (
    <header>
        <Navbar className="bg-bot-yellow" expand="md" {...props}>
            <Container>
                <Navbar.Brand href="/"><img width={90} height={50} src={bwLogo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarSupportedContent" />
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link href="/">Dashboard</Nav.Link>
                        <Nav.Link href="/elite">Business Elite</Nav.Link>
                        <Nav.Link href="/sessionend">Logout</Nav.Link >
                    </Nav>
                </Navbar.Collapse>
            </Container>

        </Navbar>
    </header>


)));