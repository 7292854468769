import { observable, action } from 'mobx';
import ApiClient from '../util/ApiClient';


export default class AuthStore {

    @observable loading = false;
    @observable buttonDisabled = false
    @observable buttonText = "Login"
    @observable user = {
        token: '',
        expToken: '',
    }


    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action login(email, password) {

        var self = this;
        ApiClient.postUrlEncoded('login', {
            email: email,
            password: password,
        }).then((response) => {
            this.buttonDisabled = false;
            this.buttonText = "Login";
            if (response.data.role && response.data.role.length > 0) {
                self.user.expToken = response.data.expires_in;
                self.user.token = response.data.access_token;
                localStorage.setItem('token', self.user.token);
                self.rootStore.routerStore.push('/');
            }
            else {
                this.rootStore.error = "Error Retrieving Data"
            }

        }).catch((error) => {
            this.buttonDisabled = false;
            this.buttonText = 'Login';
            if (error.response && error.response.data) {
                this.rootStore.error = error.response.data;
            }
        });

    }



}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

