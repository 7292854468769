import React, { Component } from 'react';
import './App.css';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Login from './pages/login/Login';
import UserHome from './pages/home/UserHome';
import HtmlBuilder from './pages/builder/HtmlBuilder';
import TemplateMain from './pages/template/TemplateMain';
import Elite from './pages/business/Create';
import { AppBar } from './components/AppBar';

const checkAuth = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    return true;

  } catch (e) {
    return false;
  }
}
const clearData = () => {
  localStorage.clear()
  window.location = '/'
}
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    checkAuth() ? (
      <Component {...props} />
    ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
  )} />
)
const logout = ({ component: Component, ...rest }) => (
  clearData()
)

@inject('rootStore')
@observer
class App extends Component {
  componentWillMount() {

    const rootStore = this.props.rootStore;

  }
  showNavBar = () => {

    const pathname = window.location.pathname;
    if (pathname === "/login") {
      return false;
    }
    return true;
  }
  render() {
    const rootStore = this.props.rootStore;
    return (
      <div>
        {this.showNavBar() && <AppBar />}
        <main role="main" style={{ padding: '1rem' }}>
          <Switch>
            <AuthRoute exact path="/" component={UserHome} />
            <Route exact path="/login" render={props => <Login {...props} />} />
            <Route exact path="/builder" render={props => <HtmlBuilder {...props} />} />
            <Route exact path="/template" render={props => <TemplateMain {...props} />} />
            <Route exact path="/elite" render={props => <Elite {...props} />} />
            <Route exact path="/sessionend" component={logout} />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withRouter(App);
