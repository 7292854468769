import React from 'react';
import { inject, observer } from "mobx-react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import '../css/templateBuilder.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Dropdown from 'react-bootstrap/Dropdown'
import trashIcon from '../assets/trash_icon.png';
import addIcon from '../assets/add-icon.png';
import Alert from 'react-bootstrap/Alert'
import Collapsible from 'react-collapsible';
function QuestionDetail(props) {
    return (
        <div>
            <Draggable draggableId={props.id} index={props.index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Collapsible trigger={(props.index + 1) + ". " + props.all[props.index].question.question} triggerDisabled={props.all[props.index].question.questionId == "605c261c411acd2d10d716av"}>
                            <Row>
                                <Col className="itemMain" xs={7} lg={9}>

                                    <RenderSeriesListItem all={props.all} step={props.index}
                                        seriesQuestionTypes={props.seriesQuestionTypes}
                                        saveNewQuestionClick={props.saveNewQuestionClick}
                                        showSavedMessage={props.showSavedMessage}
                                        updateQuestionClick={props.updateQuestionClick}
                                        onQuestionDataChange={props.onQuestionDataChange}
                                        onRemoveQuestionClick={props.onRemoveQuestionClick} />

                                </Col>

                            </Row>
                        </Collapsible>
                    </div>
                )}
            </Draggable>
        </div>

    );
}


function RenderSeriesListItem(props) {
    return <div>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="controlQuestion">
                    <Form.Label>Question</Form.Label>
                    <Form.Control as="textarea" rows={2} value={props.all[props.step].question.question} onChange={props.onQuestionDataChange(props.step, 'question')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="controlRephrasedQuestion">
                    <Form.Label>Rephrased Question</Form.Label>
                    <Form.Control as="textarea" rows={2} value={props.all[props.step].question.rephrasedQuestion} onChange={props.onQuestionDataChange(props.step, 'rephrasedQuestion')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="positiveText">
                    <Form.Label>Positive Button Text</Form.Label>
                    <Form.Control type="text" placeholder="Button Text" value={props.all[props.step].question.positiveText} onChange={props.onQuestionDataChange(props.step, 'positiveText')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="positiveStepCounter">
                    <Form.Label>Positive Step Counter</Form.Label>
                    <Form.Control type="number" placeholder="Positive Button Counter" value={props.all[props.step].question.positiveStepCounter} onChange={props.onQuestionDataChange(props.step, 'positiveStepCounter')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="positiveHref">
                    <Form.Label>Positive Href</Form.Label>
                    <Form.Control type="text" placeholder="Positive Href" value={props.all[props.step].question.positiveHref} onChange={props.onQuestionDataChange(props.step, 'positiveHref')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="positiveQuestionType">
                    <Form.Label>Question Type</Form.Label>
                    <Dropdown onSelect={props.onQuestionDataChange(props.step, 'positiveQuestionType')} style={{ marginBottom: '16px' }}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" >
                            {props.all[props.step].question.positiveQuestionType ? props.all[props.step].question.positiveQuestionType : "Select Question Type"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {props.seriesQuestionTypes.map((type) => {
                                return (<Dropdown.Item eventKey={type}>{type}</Dropdown.Item>)
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="toolTipMessage">
                    <Form.Label>Tool Tip Message</Form.Label>
                    <Form.Control type="text" placeholder="Tool Tip Message" value={props.all[props.step].question.toolTipMessage} onChange={props.onQuestionDataChange(props.step, 'toolTipMessage')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="imageHelper">
                    <Form.Label>Image Helper URL:</Form.Label>
                    <Form.Control type="text" placeholder="Image Helper Url" value={props.all[props.step].question.imageHelper} onChange={props.onQuestionDataChange(props.step, 'imageHelper')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="autoLabel">
                    <Form.Label>Auto Label:</Form.Label>
                    <Form.Control type="text" placeholder="Auto Label Text" value={props.all[props.step].question.autoLabel} onChange={props.onQuestionDataChange(props.step, 'autoLabel')} />
                </Form.Group>
            </Col>
        </Row>
        <div hidden={!props.all[props.step].question.showNegativeButton}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="negativeText">
                        <Form.Label>Negative Button Text</Form.Label>
                        <Form.Control type="text" placeholder="Button Text" value={props.all[props.step].question.negativeText} onChange={props.onQuestionDataChange(props.step, 'negativeText')} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="negativeStepCounter">
                        <Form.Label>Negative Step Counter</Form.Label>
                        <Form.Control type="number" placeholder="Negative Step Counter" value={props.all[props.step].question.negativeStepCounter} onChange={props.onQuestionDataChange(props.step, 'negativeStepCounter')} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col>
                <Form.Group className="mb-3" controlId="negativeHref">
                    <Form.Label>Negative Href</Form.Label>
                    <Form.Control type="text" placeholder="Negative Href" value={props.all[props.step].question.negativeHref} onChange={props.onQuestionDataChange(props.step, 'negativeHref')} />
                </Form.Group>
            </Col>
        </Row>
        </div>
        <div hidden={!props.all[props.step].question.showNeutralButton}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="neutralText">
                        <Form.Label>Neutral Button Text</Form.Label>
                        <Form.Control type="text" placeholder="Button Text" value={props.all[props.step].question.neutralText} onChange={props.onQuestionDataChange(props.step, 'neutralText')} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="neutralStepCounter">
                        <Form.Label>Neutral Step Counter</Form.Label>
                        <Form.Control type="number" placeholder="Neutral Step Counter" value={props.all[props.step].question.neutralStepCounter} onChange={props.onQuestionDataChange(props.step, 'neutralStepCounter')} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col>
                <Form.Group className="mb-3" controlId="neutralHref">
                    <Form.Label>Neutral Href</Form.Label>
                    <Form.Control type="text" placeholder="Neutral Href" value={props.all[props.step].question.neutralHref} onChange={props.onQuestionDataChange(props.step, 'neutralHref')} />
                </Form.Group>
            </Col>
        </Row>
        </div>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="showPositiveButton">
                    <Form.Check type="checkbox" disabled={true} label="Show Positive" checked={props.all[props.step].question.showPositiveButton} onChange={props.onQuestionDataChange(props.step, 'showPositiveButton')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="showNegativeButton">
                    <Form.Check type="checkbox" label="Show Negative" checked={props.all[props.step].question.showNegativeButton} onChange={props.onQuestionDataChange(props.step, 'showNegativeButton')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="showNeutralButton">
                    <Form.Check type="checkbox" label="Show Neutral" checked={props.all[props.step].question.showNeutralButton} onChange={props.onQuestionDataChange(props.step, 'showNeutralButton')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="isBeforeSpreadSheetForm">
                    <Form.Check type="checkbox" label="Step Before Spreadsheet Form" checked={props.all[props.step].question.isBeforeSpreadSheetForm} onChange={props.onQuestionDataChange(props.step, 'isBeforeSpreadSheetForm')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="isSpreadSheetFormField">
                    <Form.Check type="checkbox" label="Spreadsheet Form" checked={props.all[props.step].question.isSpreadSheetFormField} onChange={props.onQuestionDataChange(props.step, 'isSpreadSheetFormField')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="isAfterSpreadSheetFormSubmitted">
                    <Form.Check type="checkbox" label="Step After Spreadsheet Form" checked={props.all[props.step].question.isAfterSpreadSheetFormSubmitted} onChange={props.onQuestionDataChange(props.step, 'isAfterSpreadSheetFormSubmitted')} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="isRepeaterSetup">
                    <Form.Check type="checkbox" label="Repeater Setup" checked={props.all[props.step].question.isRepeaterSetup} onChange={props.onQuestionDataChange(props.step, 'isRepeaterSetup')} />
                </Form.Group>
            </Col>
        </Row>
        <div hidden={!props.all[props.step].question.isRepeaterSetup}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="repeatMaxCounter">
                        <Form.Label>Repeat Max Count</Form.Label>
                        <Form.Control type="number" placeholder="Repeat Max Count" value={props.all[props.step].question.repeatMaxCount} onChange={props.onQuestionDataChange(props.step, 'repeatMaxCounter')} />
                    </Form.Group>
                </Col>
            </Row>
        </div>
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="positiveText">
                    <Button variant="success" onClick={props.all[props.step].question.questionId ? props.updateQuestionClick(props.step) : props.saveNewQuestionClick(props.step)}>{props.all[props.step].question.questionId ? "Update Question" : "Save New Question"}</Button>
                    <span hidden={props.showSavedMessage} style={{ color: 'green' }}>Updated!</span>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="positiveText">
                    <Button variant="danger" onClick={props.onRemoveQuestionClick(props.step)}>Remove From Series</Button>
                </Form.Group>
            </Col>
        </Row>
    </div>
}

const SeriesList = React.memo(function SeriesList({ questions, seriesQuestionTypes, saveNewQuestionClick, showSavedMessage, updateQuestionClick,
    onQuestionDataChange, onRemoveQuestionClick, showUpgradeButton, onClickDeleteTask }) {
    return questions.map((question, index) => (
        <QuestionDetail all={questions} index={index} id={'id-' + index}
            seriesQuestionTypes={seriesQuestionTypes}
            saveNewQuestionClick={saveNewQuestionClick}
            showSavedMessage={showSavedMessage}
            updateQuestionClick={updateQuestionClick}
            onQuestionDataChange={onQuestionDataChange}
            onRemoveQuestionClick={onRemoveQuestionClick}
        />
    ));
});

export const TemplateCreator = inject("rootStore")(observer((props) => (
    <div>
        <div>
            <div>
                <Alert variant={"secondary"}>
                    <h4 style={{ textAlign: "center" }}>Series Title</h4>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="seriesName">
                                <Form.Label>Series Name</Form.Label>
                                <Form.Control type="text" placeholder="Series Name" value={props.seriesName} onChange={props.onSeriesNameChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="ttcEstimate">
                                <Form.Label>Estimated Minutes to Complete Series</Form.Label>
                                <Form.Control type="text" placeholder="Time to complete" value={props.seriesTTCEstimate} onChange={props.onSeriesTTCEstimateChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="minSubscriptionPlan">
                                <Form.Label>Minimum Subscription Plan</Form.Label>
                            </Form.Group>
                            <Dropdown onSelect={props.onSeriesMinSubscriptionSelection} style={{ marginBottom: '16px' }}>
                                <Dropdown.Toggle variant="info" id="dropdown-basic" >
                                    {props.seriesMinSubscription ? props.seriesMinSubscription : "Min Subscription Allowed"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.supportedSubscriptions.map((sub) => {
                                        return (<Dropdown.Item eventKey={sub}>{sub}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Alert>
                <div>
                    <h4 style={{ textAlign: "center" }}>Series Questions  <img src={addIcon} style={{ width: '60px', height: '60px' }} onClick={props.addNewQuestion} /></h4>
                </div>
                <DragDropContext onDragEnd={props.onTaskDragEnd} onDragStart={props.onTaskDragStart}>
                    <Droppable droppableId="list">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <SeriesList questions={props.questions} seriesQuestionTypes={props.seriesQuestionTypes}
                                    saveNewQuestionClick={props.saveNewQuestionClick}
                                    showSavedMessage={props.showSavedMessage}
                                    updateQuestionClick={props.updateQuestionClick}
                                    onQuestionDataChange={props.onQuestionDataChange}
                                    onRemoveQuestionClick={props.onRemoveQuestionClick}
                                />
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Row>
                <Col style={{ textAlign: 'center', margin: '20px 20px' }}>
                    <Button onClick={props.onSaveAllClick}>Save Changes</Button>
                    <span hidden={props.showFinalSavedMessage} style={{ color: 'green', marginLeft: '20px' }}>Saved!</span>
                </Col>
            </Row>

        </div >
        {props.dragInProgress &&
            <Row className="trashItem">
                <Col style={{ textAlign: 'center' }}>
                    <img src={trashIcon} />
                </Col>
            </Row>
        }
    </div>
)));
