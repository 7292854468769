import React, { Component } from 'react';
import { Formik } from "formik";
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import bwLogo from '../../assets/Logo_BnW@3x.png';
import ApiClient from '../../util/ApiClient';
import gLogo from '../../assets/googleLogo@3x.png';
import GoogleLogin from 'react-google-login';


@inject('rootStore')
@observer
export default class Login extends Component {
    constructor() {
        super();
        this.state = {
            apiError: '',
            forgotPasswordModalShow: false,
            forgotPasswordValue: '',
            disableForgotPasswordButton: true,
            hideForgotPasswordLabel: true,
            forgotPasswordLabelValue: '',
            forgotPasswordLabelColorStyle: {},
            submitting: false,
            disableGoogleLogin: false,
            deepSubscription: ''
        };
    }

    componentDidMount() {
        const rootStore = this.props.rootStore;

    }

    onClickForgotPassword = () => {
        this.onShowForgotPasswordModal(true)
    }
    onShowForgotPasswordModal = (show) => {
        this.setState({ forgotPasswordModalShow: show })
    }
    onForgotPasswordChange = (e) => {
        this.setState({ forgotPasswordValue: e.target.value }, () => {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.forgotPasswordValue)) {
                this.setState({ disableForgotPasswordButton: false })
            }
            else {
                this.setState({ disableForgotPasswordButton: true })
            }
        })

    }
    onForgotPasswordClick = () => {
        this.setState({ submitting: true, disableForgotPasswordButton: true, hideForgotPasswordLabel: true }, () => {
            ApiClient.post('user/password/reset', { emailAddress: this.state.forgotPasswordValue }).then((response) => {
                this.setState({
                    forgotPasswordLabelValue: 'Please check your email', forgotPasswordLabelColorStyle: { color: 'green' },
                    hideForgotPasswordLabel: false, submitting: false
                })
            }).catch((error) => {

                this.setState({
                    forgotPasswordLabelValue: error.response.data, forgotPasswordLabelColorStyle: { color: 'red' }, hideForgotPasswordLabel: false,
                    submitting: false
                })
            });
        })
    }
    responseGoogle = (response) => {
        if (response && response.tokenObj) {
            ApiClient.getGoogleUserData(response.accessToken).then((gResponse) => {
                if (gResponse && gResponse.data.ageRanges) {
                    var primary = gResponse.data.ageRanges.find(x => x.metadata.primary === true);
                    if (!primary) { this.setState({ apiError: "Error validating your age. Please try again" }); return; }
                    if (primary.ageRange === "AGE_RANGE_UNSPECIFIED" || primary.ageRange === "LESS_THAN_EIGHTEEN") { this.setState({ apiError: "You must be of age 18+ to register" }); return; }
                    ApiClient.postUrlEncoded('social',
                        { firstName: response.profileObj.givenName, lastName: response.profileObj.familyName, email: response.profileObj.email, token: response.tokenObj.id_token, signupType: 'Google' }).then((response) => {
                            if (response.status == 200) {
                                this.setState({ disableGoogleLogin: false, })
                                localStorage.setItem('token', response.data.access_token);
                                localStorage.setItem('l', response.data.access_level);
                                window.location = '/'
                            }
                            else {
                                this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
                            }
                        }).catch((error) => {
                            this.setState({ disableGoogleLogin: false, apiError: error.response.data.error })
                        });
                }
                else {
                    this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
                }

            }).catch((error) => {
                this.setState({ disableGoogleLogin: false, apiError: "Error validating your age. Please try again" })
            });
        }
        else {
            this.setState({ disableGoogleLogin: false, apiError: 'Google login error' })
        }
    }
    responseErrorGoogle = (error) => {
        this.setState({ disableGoogleLogin: false, apiError: error.error })
    }
    render() {

        const rootStore = this.props.rootStore;

        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                        <Row>
                            <Col style={{ textAlign: "center", marginBottom: "20px" }}>
                                <img src={bwLogo} width="180px" height="90px" style={{ paddingRight: '10px' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <h3>Admin Login</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row style={{ margin: "20px" }}>
                                    <Col>
                                        <div className="d-grid gap-2">
                                            <GoogleLogin
                                                clientId="968432706105-kcauvjavh3kttrt0nr1pt1ed28jg5qig.apps.googleusercontent.com"
                                                autoLoad={false}
                                                render={renderProps => (
                                                    <Button disabled={this.state.disableGoogleLogin} style={{ backgroundColor: 'rgb(243 74 56)' }} size="lg" onClick={renderProps.onClick} ><img src={gLogo} width="30px" height="30px" style={{ float: 'left' }} />Log in with Google</Button>
                                                )}
                                                buttonText="Login"
                                                scope="https://www.googleapis.com/auth/profile.agerange.read"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseErrorGoogle}
                                                onRequest={() => { this.setState({ disableGoogleLogin: true, }); }}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                                <Row style={{ margin: "20px" }}>
                                    <Col>
                                        <Formik
                                            initialValues={{
                                                userEmail: "",
                                                userPassword: "",
                                            }}
                                            onSubmit={(values, actions) => {
                                                ApiClient.postUrlEncoded('login', {
                                                    email: values.userEmail,
                                                    password: values.userPassword,
                                                }).then((response) => {
                                                    if (response.status == 200) {
                                                        if (response.data.access_level < 2) {
                                                            actions.setSubmitting(false);
                                                            values.userPassword = '';
                                                            actions.setValues(values, false)
                                                            this.setState({ apiError: "Access Denied. Please contact a Bot-It Admin." })
                                                        }
                                                        else {
                                                            localStorage.setItem('token', response.data.access_token);
                                                            localStorage.setItem('l', response.data.access_level);
                                                            window.location = '/'
                                                        }
                                                    }
                                                    else {
                                                        actions.setSubmitting(false);
                                                        values.userPassword = '';
                                                        actions.setValues(values, false);
                                                        this.setState({ apiError: "Login Error. Please try again" })
                                                    }

                                                }).catch((error) => {
                                                    actions.setSubmitting(false);
                                                    values.userPassword = '';
                                                    actions.setValues(values, false)
                                                    this.setState({ apiError: error.response.data })

                                                })


                                            }}
                                        >
                                            {formik => (
                                                <div>
                                                    <Form onSubmit={formik.handleSubmit}>
                                                        <Form.Group controlId="userEmail">
                                                            <Form.Label>Email address</Form.Label>
                                                            <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userEmail} type="email" placeholder="Enter email" required />
                                                        </Form.Group>

                                                        <Form.Group controlId="userPassword">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.userPassword} type="password" placeholder="Password" required />
                                                        </Form.Group>
                                                        <Row style={{ textAlign: 'center' }}>
                                                            <Col lg={12}>
                                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Submit"}</Button>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ textAlign: 'center' }}>
                                                            <Col lg={12}>
                                                                <span style={{ color: 'red', margin: '20px' }}>{this.state.apiError}</span>
                                                            </Col>
                                                        </Row>

                                                    </Form>

                                                </div>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container >
        )
    }
}
